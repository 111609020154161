* {
  font-synthesis: none !important;
}

html,
body,
body > div {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#react-confirm-alert {
  z-index: 9999;
}

.startup-loading {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #673ab7;
}

.mapboxgl-popup-close-button {
  border-radius: 10px;
  background-color: white;
  font-size: 24px;
}
