#dathic-powered {
  color: #032536;
}
#dathic-powered:visited {
  color: #032536;
}

.mapboxgl-ctrl-bottom-left{
  top: 0;
  bottom: unset;
}